@use '@angular/material' as mat;

@mixin averia-headline-theme($theme) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);

  .averia-headline {
    background: linear-gradient(60deg, mat.get-color-from-palette($primary, default, 0.9), mat.get-color-from-palette($primary));
    color: mat.get-color-from-palette($primary, default-contrast);
  }

  .averia-headline-title {
    @include mat.typography-level(map-get($theme, 'typography'), 'headline-5');
  }
}
