@use '@angular/material' as mat;
@use '../styles/shared/theme' as config;
@use '@ng-matero/extensions' as mtx;
@use '../shared/headline/headline_theme' as headline;

@import "leaflet/dist/leaflet.css";

html, body {
  height: 100%;
}

body {
  margin: 0;
}

a {
  color: mat.get-color-from-palette(mat.define-palette(mat.$light-blue-palette, 600, 400, 700));
  text-decoration: none;
}

.averia {
  @include mat.core();
  @include mat.all-component-themes(config.$theme);
  @include mat.typography-hierarchy(config.$theme);
  @include mtx.datetimepicker-theme(config.$theme);
  @include headline.averia-headline-theme(config.$theme);

  //@include mat.private-form-field-density(-1);
  //@include mat.form-field-density(-3);

  .side-form {
    display: flex;
    flex-direction: column;

    .side-form-actions {
      display: flex;
      justify-content: flex-end;
    }
  }

  .mat-mdc-table {
    //width: 100%;
    //white-space: nowrap;
    //
    //.mat-mdk-header-cell, .mat-mdk-cell {
    //  padding: 0 10px;
    //  flex-shrink: 0;
    //}
    //
    //tr.mat-mdk-row, tr.mat-mdk-footer-row {
    //  height: 38px;
    //}
    .mat-mdc-header-cell {
      white-space: nowrap;
    }

    .mat-mdc-row, .mdc-data-table__content {
      line-height: initial;
    }

    .mat-mdc-row {
      --mat-table-row-item-container-height: 38px;
    }

    .mat-mdc-icon-button {
      --mdc-icon-button-state-layer-size: 36px;
      --mdc-icon-button-state-state-size: 36px;
      padding: 6px;
      //width: 36px;
      //height: 36px;
      //line-height: 36px;
    }

    .min-width {width: 1px; white-space: nowrap;}
  }

  .mat-mdc-paginator-container {
    flex-wrap: nowrap;
    white-space: nowrap;
    padding: 16px 0;
  }

  .ngx-json-viewer {
    .segment-type-object > .segment-main > .segment-value,
    .segment-type-array > .segment-main > .segment-value {
      display: none;
    }

    .segment-main .segment-separator {
      display: none;
    }

    .segment-main.expanded .segment-separator {
      display: inline-block;
    }

    .segment .segment-main .toggler:after {
      content: "▶" !important; // important! to override default ngx-json-viewer library style
    }
  }

  *[hidden] {
    display: none !important;
  }

  //background: #eee
//
//  .mat-mdk-table {
//    width: 100%;
//    white-space: nowrap;
//
//    .mat-mdk-header-cell, .mat-mdk-cell {
//      padding: 0 10px;
//      flex-shrink: 0;
//    }
//
//    tr.mat-mdk-row, tr.mat-mdk-footer-row {
//      height: 38px;
//    }
//
//    .mat-mdk-icon-button {
//      width: 36px;
//      height: 36px;
//      line-height: 36px;
//    }
//
//    .mat-mdk-column-options {
//      width: 1px;
//      white-space: nowrap;
//      text-align: right;
//    }
//
//  }
//
//  .overflow-content {
//    //overflow: auto;
//    //margin: 0 -1rem;
//    //padding: 0 1rem;
//  }
//
//
//  a {
//    //color: mat.get-color-from-palette(mat.define-palette(mat.$light-blue-palette, 600, 400, 700));
//    //color: mat-color(map-get($theme, accent));
//    text-decoration: none;
//    //&:hover {
//    //  text-decoration: underline;
//    //}
//  }
//
//  .mat-mdk-tab-body-wrapper .mat-mdk-tab-body-content {
//    overflow: initial;
//  }
//
//  .mat-mdk-card {
//    border-radius: 8px !important;
//    box-shadow: 0 1px 4px 0 rgba(0,0,0,.14) !important;
//
//    .mat-mdk-card-content {
//      &.outlined {
//        margin: -1rem;
//        overflow: auto;
//      }
//    }
//
//    .mat-mdk-table {
//      background: transparent;
//    }
//  }
//
//  //.mat-mdk-card [class*=mat-card-header-]:not(.card-header-icon):not(.card-header-text):not(.card-header-image) {
//  //  border-radius: 3px;
//  //  margin-top: -20px;
//  //  padding: 15px;
//  //  background: lightgrey;
//  //}
//  //
//  //.mat-mdk-card .card-header-primary .card-icon, .mat-mdk-card .card-header-primary .card-text, .mat-mdk-card .card-header-primary:not(.card-header-icon):not(.card-header-text) {
//  //  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(156, 39, 176, .4);
//  //}
//  //
//  //.mat-mdk-card .card-header-primary .card-icon, .mat-mdk-card .card-header-primary .card-text, .mat-mdk-card .card-header-primary:not(.card-header-icon):not(.card-header-text), .mat-mdk-card.bg-primary, .mat-mdk-card.card-rotate.bg-primary .back, .mat-mdk-card.card-rotate.bg-primary .front {
//  //  background: linear-gradient(60deg, #ab47bc, #8e24aa);
//  //}
//  //
//  //.mat-mdk-card [class*=mat-card-header-] {
//  //  margin: 0 15px;
//  //  padding: 0;
//  //  position: relative;
//  //}
//}
}
